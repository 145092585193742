.order_item {
    width: 100%;
    background-color: #1c1c21;
    border-radius: 40px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    cursor:pointer;
  }
  
  .order_number {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .order_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .done {
    color: rgba(0, 204, 204, 1);
  }
  
  .ingredients_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .ingredients_price {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .ingredients_list {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    margin-right: 20px;
  }
  
  .ingredient_wrapper {
    width: 68px;
    height: 68px;
    display: grid;
    place-content: center;
    border-radius: 50%;
    background: linear-gradient(63.18deg, #801ab3 0%, #4c4cff 100%);
  }
  
  .ingredient_wrapper:not(:first-child) {
    margin-left: -16px;
  }
  
  .ingredient {
    width: 64px;
    height: 64px;
    display: grid;
    place-content: center;
    border-radius: 50%;
    background: #131316;
    position: relative;
  }
  
  .count_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: #1c1c21;
    opacity: 0.6;
    display: grid;
    place-content: center;
  }
  
  
  