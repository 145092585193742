.wrapper {
  padding: 88px 20px 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: bottom;
  background-position-x: -25vw;
  background-repeat: no-repeat;
}

.container {
  padding: var(--container-padding);
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--container-padding) * 2);
}

.content {
  margin: auto;
}

.link {
  color: var(--color-title);
}
