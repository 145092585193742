.content_wrapper {
    display: flex;
    gap: 60px;
  }
  
  .header {
    padding: 40px 0 20px 0;
    align-self: flex-start;
  }
  
  .order_list {
    width: 640px;
    overflow-y: scroll;
    height: calc(100vh - 268px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    scrollbar-width: thin;
    padding-right: 5px;
  }
  
  .order_list::-webkit-scrollbar {
    width: 8px;
  }
  .order_list::-webkit-scrollbar-track {
    background: #2f2f37;
  }
  .order_list::-webkit-scrollbar-thumb {
    background: #8585ad;
  }
  
  .info_wrapper {
    width: 580px;
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
  
  .info_table {
    display: flex;
    gap: 36px;
  }
  
  .done,
  .progress {
    width: calc((100% - 36px) / 2);
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: hidden;
  }
  
  .list {
    margin: 0;
    padding: 0;
    display: flex;
    max-height: calc(24px * 10);
    flex-direction: column;
    flex-wrap: wrap;
  }
  
  .list_item {
    list-style: none;
    margin-right: 10px;
  }
  
  .list_item_done {
    color: rgba(0, 204, 204, 1);
  }
  
  .count {
    text-shadow: 0px 4px 32px #3333ff80, 0px 0px 8px #3333ff40, 0px 0px 16px #3333ff40;
  }
  