.container {
  width: calc(50% - 20px);
  margin-right: 20px;
}

.scroll_list {
  overflow-y: scroll;
  max-height: 65vh;
  scrollbar-width: thin;
}
.scroll_list::-webkit-scrollbar {
  width: 8px;
}

.scroll_list::-webkit-scrollbar-track {
  background: #1C1C21; 
}
 
.scroll_list::-webkit-scrollbar-thumb {
  background: #8585AD; 
}


.header_tabs {
  width: 100%;
  margin-bottom: 40px;
}

.tabs {
  width: 100%;
  display: flex;
}

.sec_items {
  margin-bottom: 40px;
}

.items_list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 32px;
  margin-left: 0;
  padding-left: 0;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item_image {
  padding: 0 16px;
}

.item_description {
  display: flex;
  justify-content: center;
  align-items: center;
}