.container {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

}

.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu_right {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

}

.menu_link {
  display: flex;
  text-decoration: none;
  color: #8585AD;

}

.menu_link:hover {
  color: #F2F2F3;
}

.logo {
  align-self: center;

}