.wrapper {
  max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    box-sizing: border-box;
  }

