.profile {
  
    width: 100%;
    max-width: 1240px;
    display: flex;
    margin: calc(120px + 88px) auto 0;
  }
  
  .nav_container {
    max-width: 320px;
    width: 100%;
  }
  
  .nav__text {
    margin: 80px 0 0;
    opacity: 0.4;
  }